import * as React from "react"
import LegalLayout from "../components/legal-layout"
import TermsOfUseAgreement from "../components/terms-of-use-agreement"

import DefaultLayout from "../components/default-layout"
import Seo from "../components/seo"
import Layout from "../components/app-layout"

const TermsOfUseAgreementPage = () => (
  <Layout>
    <DefaultLayout>
      <LegalLayout>
        <TermsOfUseAgreement />
      </LegalLayout>
    </DefaultLayout>
  </Layout>
)

export const Head = () => <Seo title="Terms of use" />

export default TermsOfUseAgreementPage


